import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getSessionStorageWithExpiry } from './utils/utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConditionalRoute = () => {
  const { currentUser, patientDetails, patientData } = useAuth();
  const location = useLocation();
  const query = useQuery();
  const loadedAllData = currentUser && patientDetails && patientData;
  const isFollowup = location.pathname.includes('followup');

  // console.log('currentUser', currentUser);
  // console.log('patientDetails', patientDetails);
  // console.log('patientData', patientData);

  const isRescheduleOverride = getSessionStorageWithExpiry('rescheduling');

  const neverScheduled = patientDetails?.never_scheduled;
  const intakeComplete = patientData && (patientData?.intake_complete || patientData?.intake_complete === 'undefined');
  const hasMeetingScheduled = patientDetails?.meeting_in_patient_timezone_full_TZ;

  const searchParams = new URLSearchParams(location.search);

  if (isFollowup && patientData?.magic_id && (!query.get('dx') && !query.get('dr')) ) {
    window.location = 'https://joyous.team/followup/' + patientData.magic_id;
    return;
  }

  if (loadedAllData && !neverScheduled && !intakeComplete) {
    return <Navigate to={`/complete-intake?${searchParams.toString()}`} />;
  }


  if (hasMeetingScheduled && !isRescheduleOverride) {
    return <Navigate to={`/already-booked?${searchParams.toString()}`} />;
  }

  return <Outlet />;
};

export default ConditionalRoute;
