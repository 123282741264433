import React, { useRef, useEffect, useState } from 'react';
import DropdownInput from './DropdownInput';
import stateCodeToName from '../utils/state_code_to_name.json';

const AddressInput = ({ onChange, value, disabled, className }) => {
  // const [address, setAddress] = useState(value.address || '');
  // const [city, setCity] = useState(value.city || '');
  // const [state, setState] = useState(value.state || '');
  // const [zipCode, setZipCode] = useState(value.zipCode || '');
  const addressRef = useRef(null);
  const disabledClassNames = disabled ? 'bg-disabled' : '';

  className = `
    mt-1 block w-full border border-light-gray rounded-md shadow-lg p-3 font-semibold
    ${className}
    ${disabledClassNames}
  `;

  const labelClassName = 'block text-md font-bold';

  const stateCodes = Object.keys(stateCodeToName);
  let stateOptions = stateCodes.map(stateCode => ({label: stateCodeToName[stateCode], value: stateCode}));


  useEffect(() => {
    const loadGoogleMapsScript = (callback) => {
      if (typeof window.google === 'undefined') {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = callback;
        document.head.appendChild(script);
      } else {
        callback();
      }
    }

    const initAutocomplete = () => {
      if (!window.google) {
        console.error("Google API is not loaded");
        return;
      }

      const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
        types: ['address'],
        fields: ['address_components', 'geometry'],
        componentRestrictions: { country: 'us' }, // Optional: restricts to the US
      });

      autocomplete.setFields(['address_component']);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;

        const street = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';
        const route = addressComponents.find(component => component.types.includes('route'))?.long_name || '';
        const updatedAddress = `${street} ${route}`;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
        const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '';
        const zipCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name || '';

        // setAddress(updatedAddress);
        // setCity(city);
        // setState(state);
        // setZipCode(zipCode);

        if (onChange) {

          // onChange({target: {name: 'address', value: updatedAddress}});
          // onChange({target: {name: 'city', value: city}});
          // onChange({target: {name: 'state', value: state}});
          // onChange({target: {name: 'zipCode', value: zipCode}});
          onChange({
            address: updatedAddress,
            city,
            state,
            zipCode,
          })
        }
      });
    };

    loadGoogleMapsScript(initAutocomplete);
  }, [onChange]);

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className={labelClassName}>Shipping Address*</label>
          <input
            name="address"
            type="text"
            ref={addressRef}
            value={value.address || ''}
            onChange={e => onChange(e)}
            placeholder="Enter your address"
            className={className}
            disabled={disabled}
            autoComplete='off'
          />
        </div>
        <div>
          <label className={labelClassName}>City *</label>
          <input
            name="city"
            type="text"
            value={value.city || ''}
            onChange={e => onChange(e)}
            placeholder="City"
            className={className}
            disabled={disabled}
            autoComplete='off'
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
        <div>
          <label className={labelClassName}>State *</label>
          <DropdownInput
            name="state"
            type="text"
            value={value.state || ''}
            onChange={e => onChange(e)}
            // placeholder="State"
            options={stateOptions}
            className={className}
            disabled={disabled}
            autoComplete='off'
          />
        </div>
        <div>
          <label className={labelClassName}>Zip Code*</label>
          <input
            name="zipCode"
            type="text"
            value={value.zipCode || ''}
            onChange={e => onChange(e)}
            placeholder="Zip Code"
            className={className}
            disabled={disabled}
            autoComplete='off'
          />
        </div>
      </div>
    </div>
  );
};

export default AddressInput;
