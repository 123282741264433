
import { ReactComponent as InfoIcon } from '../assets/info.svg';

export default function DropdownInput({label, placeholder, onChange, value, name, options, className, disabled, tooltipText}) {

  placeholder = placeholder || 'Select an option...';

  options.unshift({label: placeholder, value: ''});

  const disabledClassNames = disabled ? 'bg-disabled disabled:text-black disabled:bg-disabled disabled:border-light-gray' : '';

  className = `
    mt-1 block w-full border border-light-gray rounded-md shadow-md p-3 font-semibold text-black
    ${disabledClassNames}
    ${className}
  `;

  return (
    <div>
      <label className="block text-md font-bold flex items-center">
        {label}
        {tooltipText && (
          <span className="ml-2 text-purple cursor-pointer relative group">
            <InfoIcon className="h-4 w-4" />
            <span className="absolute left-0 bottom-full mb-2 w-64 p-2 bg-purple text-white text-s rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg z-10">
              {tooltipText}
            </span>
          </span>
        )}
      </label>
      <select
        name={name}
        id={name}
        className={className}
        value={value}
        onChange={e => onChange(e)}
        placeholder="State"
        disabled={disabled}
      >
        {options.map(o => (
          <option key={o.value} value={o.value}>{o.label}</option>
        ))}
      </select>
    </div>
  );
}
