import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireTimeslot = ({ children }) => {
  const location = useLocation();

  if (!location.state?.timeslot) {
    const searchParams = new URLSearchParams(location.search);
    return <Navigate to={`/book-appointment?${searchParams.toString()}`} replace />;
  }


  return children;
};

export default RequireTimeslot;
