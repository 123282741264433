import React from 'react';
import { Transition } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as Joyous } from '../assets/joyous.svg';

function Modal({ isOpen, onCancel, onClose, onProceed, title, message, proceedText, cancelText }) {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      {/* Modal Overlay */}
      <div
        className="font-sans relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onClick={onClose} // Added onClick handler to close modal when clicking outside
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Background Overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
        </Transition.Child>

        {/* Modal Content */}
        <div className="font-sans fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal Box */}
              <div
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                onClick={(e) => e.stopPropagation()} // Prevents modal from closing when clicking inside
              >
                {/* Close Button */}
                <button
                  onClick={onClose}
                  className="absolute mt-[-7px] text-2xl top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  &times;
                </button>

                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <Joyous />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-bold text-lg leading-6 text-gray-900" id="modal-title">
                        {title || 'Title'}
                      </h3>
                      <div className="mt-2">
                        <ReactMarkdown>{message || 'Message'}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={onProceed}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-purple px-3 py-2 text-md font-semibold text-white shadow-sm hover:bg-violet-500 sm:ml-3 sm:w-auto"
                  >
                    {proceedText || 'Proceed'}
                  </button>
                  <button
                    onClick={onCancel}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    {cancelText || 'Cancel'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default Modal;
