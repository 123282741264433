import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import _, { set } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStateFullName, getDrFromDxOrDr } from '../utils/utils';
import TimeslotsDaysContainer from './TimeslotsDaysContainer';
import TimezonePicker from './TimezonePicker';
import Loader from './Loader';

const meetingTypeMap = {
  'new': 'New Patient Mental Wellbeing',
  'followup': 'Follow Up Mental Wellbeing',
  '500961': 'New Patient Mental Wellbeing',
  '487240': 'Follow Up Mental Wellbeing',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TimeslotPicker() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsEntering(true);
  }, []);

  const query = useQuery();
  const [stateCode, setStateCode] = useState(query.get('state')?.toUpperCase());
  const [type, setType] = useState(query.get('type')); // new patient is default
  const [providerName, setProviderName] = useState('');
  const isFollowup = location.pathname.includes('followup');

  const stateFullName = getStateFullName(stateCode);
  // const providerName = sessionStorage.getItem('drData')?.first_name;
  const meetingType = location.pathname.includes('followup') ? 'Follow Up Mental Wellbeing' : 'New Patient Mental Wellbeing';
  const beginningOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  const [timezone, setTimezone] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [availableTimeslotsKeys, setAvailableTimeslotsKeys] = useState([]);
  const [drData, setDrData] = useState(null);
  const [dr, setDr] = useState(query.get('dr'));
  const [dx, setDx] = useState(query.get('dx'));

  const sanitizeAvailableDates = (dates) => {
    // Filter object where the values are true
    const availableDates = Object.keys(dates).filter(date => dates[date]);

    // Sort the dates in ascending order
    availableDates.sort((a, b) => new Date(a) - new Date(b));
    return availableDates;
  };

  // Fetch the dates that have available timeslots
  useEffect(() => {

    // if (!stateCode) {
    //   query.set('state', 'CA');
    //   setStateCode('CA');
    //   missingParams = true;
    // }

    // get dr
    // if (isFollowup && query.get('dx')) {
    //   getDr();
    // }


    if (!type) {
      if (isFollowup) {
        query.set('type', '487240');
        setType('487240');
      } else {
        query.set('type', '500961');
        setType('500961');
      }
    }


    if (!timezone) {
      console.log('no timezone')
      return;
    } else {
      console.log('timezone', timezone)
    }


    const fetchAvailableDates = async () => {
      setIsLoading(true);

      let drDataResult;

      if (isFollowup && (dx || dr)) {
        drDataResult = await getDrFromDxOrDr({dx, dr});

        if (!_.isEmpty(drDataResult)) {
          sessionStorage.setItem('drData', JSON.stringify(drDataResult));
          setDrData(drDataResult);
          setProviderName(drDataResult.first_name + ' ' + drDataResult.last_name);
        } else {
          navigate('/select-state', { replace: true });
          return;
        }

      } else if (isFollowup && !dx && !dr) {
        navigate('/select-state', { replace: true });
        return;
      }

      try {
        const endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableDates';
        const params = {
          state: stateCode,
          timezone,
          startDate: beginningOfMonth,
          endDate: endOfMonth,
          dr: drDataResult?.dr || '',
          appointmentType: meetingType,
          redirect: 'https:/google.com/'
        };

        const response = await axios.get(endpoint, { params });
        if (response?.data.availableDates) {
          const sanitizedAvailableDates = sanitizeAvailableDates(response.data.availableDates);

          if (sanitizedAvailableDates.length > 0) {
            setAvailableDates(sanitizedAvailableDates);
          } else {
            console.log('No available dates');

            setIsLoading(false);
          }
        }

      } catch (error) {
        console.error('Error fetching available dates:', error);
      }

    };

    fetchAvailableDates();
  }, [stateCode, timezone]);

  // Fetch the timeslots for the selected date
  useEffect(() => {
    if (!stateCode && _.isEmpty(drData)) {
      return;
    }

    const fetchAvailableTimeslots = async () => {
      if (availableDates.length === 0) {
        return;
      }

      setIsLoading(true);

      try {
        const endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableTimeSlots';
        const params = {
          state: stateCode,
          timezone,
          startDate: availableDates[0],
          endDate: availableDates[availableDates.length - 1],
          dr: drData?.dr || '',
          appointmentType: meetingType,
          redirect: 'https:/google.com/'
        };

        const response = await axios.get(endpoint, { params });

        if (response?.data.slots) {
          const slots = response.data.slots;
          // Extend timeslots to have a field called 'type' and 'state
          _.each(slots, (timeslots, date) => {
            slots[date] = _.map(timeslots, (timeslot) => ({ ...timeslot,
              type,
              state: stateCode,
              timezone,
            }));
          });

          setAndShowTimeslots(slots);
        }

      } catch (error) {
        console.error('Error fetching available timeslots:', error);
      }
    };

    fetchAvailableTimeslots();
  }, [availableDates]);

  const setAndShowTimeslots = (timeslots) => {
    setAvailableTimeslots(timeslots);
    setAvailableTimeslotsKeys(Object.keys(timeslots));

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };


  const handleTimeslotClick = timeslot => {
    setIsExiting(true);
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      const navigatePath = isFollowup ? `/followup/form?${searchParams.toString()}` : `/book-appointment/form?${searchParams.toString()}`;
      const formData = location.state?.formData;

      navigate(navigatePath, {
        state: {
          timeslot,
          isEntering: true,
          ...formData && { formData },
        }
      });
    }, 500);
  };

  return (
    <div
      className={`min-h-screen flex flex-col font-sans fixed top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
        isEntering && !isExiting ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      >
      <main className="flex-1 overflow-y-auto p-4">
        <div className="h-full p-6 max-w-md mx-auto bg-white rounded-lg text-center mt-0">
          <div className="space-y-4">
            <div className="mt-3">
              <p className="text-3xl font-extrabold">{!isFollowup ? `Joyous ${stateFullName || ''}` : providerName}</p>
              <p className="text-sm font-bold text-gray mt-2">{meetingType}</p>
              <p className="text-lg font-semibold text-gray text-center mt-4">Select Timezone</p>

              <TimezonePicker
                onChange={setTimezone}
              />

              {isLoading &&
                <Loader
                  className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}
                />
              }

              {
                _.map(availableTimeslots, (timeslots, date) => (
                  <TimeslotsDaysContainer
                    key={date}
                    date={date}
                    timeslots={timeslots}
                    onClick={handleTimeslotClick}
                    className={`${date === availableTimeslotsKeys[availableTimeslotsKeys.length - 1] ? 'pb-40' : 'pb-8'} transition-opacity duration-500 ${!isLoading ? 'opacity-100' : 'opacity-0'}`}
                  />
                ))
              }
              {_.isEmpty(availableTimeslots) &&
                <div className={`transition-opacity duration-1000 ${!isLoading ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="mt-20 text-2xl w-30 font-semibold text-gray text-center mt-4">
                    <p>
                      Sorry, there are no appointments available at this time.
                    </p>
                    <p>Please try again tomorrow.</p>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TimeslotPicker;
