import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { ReactComponent as Loading } from '../assets/loading.svg';
import { setSessionStorageWithExpiry } from '../utils/utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AlreadyBooked = () => {
  const location = useLocation();
  const { currentUser, patientDetails, patientData } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const everythingLoaded = currentUser && patientDetails && patientData;
  const [isLoading, setIsLoading] = useState(false);

  const hasMeetingScheduled = patientDetails?.meeting_in_patient_timezone_full_TZ;

  useEffect(() => {
    if (!hasMeetingScheduled) {
      if (query.get('dx') || query.get('dr')) {
        navigate(`/followup?${location.search}`);
      } else {
        navigate(`/book-appointment?${location.search}`);
      }
    }
  }, [hasMeetingScheduled, currentUser, patientDetails, patientData]);

  console.log('details', patientDetails);

  const meetingTime = patientDetails?.meeting_in_patient_timezone_full_TZ;

  const onClickKeepMeeting = e => {
    e.preventDefault();
    window.location = 'https://be.joyous.team/';
  };

  const onClickBookNewMeeting = async (e) => {
    console.log('clicked book new meeting')
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    const meetingID = patientDetails?.appointmentId;
    console.log(meetingID)

    if (meetingID) {
      try {
        const searchParams = new URLSearchParams(location.search);
        let result = await axios.get(`https://drchrone-api.herokuapp.com/reschedule_my_meeting/?meeting_id=${meetingID}`);
        console.log('rescheduled?', result)

        setSessionStorageWithExpiry('rescheduling', true, 1000 * 60 * 5);
        if (query.get('dx') || query.get('dr')) {
          navigate(`/followup?${searchParams.toString()}`);
        } else {
          navigate(`/book-appointment?${searchParams.toString()}`);
        }
      } catch (e) {
        console.error('Error rescheduling meeting', e);
      } finally {
        setIsLoading(false);
      }

    }
  };


  return (
    <div>
      {everythingLoaded &&
        <div className="font-sans flex flex-col items-center justify-center h-screen bg-gray-50">
          <h2 className="text-center text-lg md:text-xl font-medium text-gray-800 mb-6">
            You already have an existing meeting
            <br />
            on {meetingTime}
          </h2>
          <h3 className="text-center text-gray-800 font-medium mb-4">
            Would you like to
          </h3>
          <div className="flex space-x-4 mb-6">
            <button
              className="bg-deep-purple hover:bg-purple-600 text-white font-semibold py-3 px-6 rounded-md focus:outline-none"
              onClick={onClickKeepMeeting}
            >
              Keep this meeting
            </button>

            {}
            <button
              className="bg-purple hover:bg-purple text-white font-semibold py-3 px-6 rounded-md focus:outline-none min-w-[184.27px] flex justify-center items-center"
              onClick={onClickBookNewMeeting}
            >
              {isLoading ?
                <Loading className="w-6 h-6 animate-spin" />
                  :
                'Book new meeting'
              }
            </button>
          </div>
          <p className="text-center text-gray-600">
            Booking a new meeting will cancel your existing meeting
          </p>
        </div>
      }
    </div>
  );
};

export default AlreadyBooked;
