import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet, useSearchParams } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RequireParams = () => {
  const location = useLocation();
  let query = useQuery();
  const pathName = location.pathname;
  const isFollowup = pathName.includes('followup');
  const isAlreadyBooked = pathName.includes('already-booked');

  if (isAlreadyBooked && (query.get('dx') || query.get('dr'))) {
    return <Navigate to={`/followup?${query.toString()}`} replace />;
  }

  if (!isFollowup && !query.get('state')) {
    return <Navigate to={`/select-state?${query.toString()}`} replace />;
  }

  if (isFollowup && !query.get('type')) {
    query.set('type', '500961'); // new patient
  }

  if (isFollowup && !query.get('type')) {
    query.set('type', '487240'); // follow-up
  }


  // if (isFollowup && query.get('dx')) {
  //   const dx = query.get('dx');
  //   console.log('dx', dx);
  //   const dr = await getDrFromDx(dx);

  //   console.log('dr', dr)




  //   if (dr) {
  //     sessionStorage.setItem('dr', dr);
  //     query.delete('dx');
  //   }
  // }



  return <Outlet />;
};

export default RequireParams;
