// AuthContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from './utils/firebase';
import { getDetailsByUid } from './utils/utils';
import { getPatientByUid, updatePatientData } from './utils/firebase_api';
// import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [patientData, setPatientData] = useState(null);

  // const query = useQuery();

  // The cookie is written in the redirect from a new meeting with magic id
  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);  // Store the full user object, including uid
      console.log('user', user);

      if (user) {
        const uid = user.uid;

        try {
          await updatePatientData(uid, {
            id: user.providerData[0].uid,
            provider: user.providerData[0].providerId,
            phoneNumber: user.providerData[0].phoneNumber,
            uid: uid,
          });
        } catch (e) {
          console.error('Error updating patient data:', e);
        }

        try {
          const details = await getDetailsByUid(uid);
          setPatientDetails(details || {});
          console.log('details', details);
        } catch (e) {
          console.error('Error getting details by uid:', e);
        }

        try {
          const data = await getPatientByUid(uid || {});
          setPatientData(data);

          // run getPatientByUid again in 2 seconds
          if (!data.magic_id) {
            setTimeout(async () => {
              const data = await getPatientByUid(uid || {});
              setPatientData(data);
            }, 2000);
          }

          console.log('data', data);
        } catch (e) {
          console.error('Error getting patient by uid:', e);
        }

      }
    });

    let tkn = getCookie('token');
    if (tkn) {
      auth.signInWithCustomToken(tkn);
      document.cookie = 'token=;';
    };

    return unsubscribe; // Clean up the listener on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, patientDetails, patientData }}>
      {children}
    </AuthContext.Provider>
  );
};
