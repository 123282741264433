import React from 'react';
import 'tailwindcss/tailwind.css';
import moment from 'moment';

function Timeslots({ timeslot, index, onClick }) {

  const timezone = timeslot.timezone;

  return (
    <div className="flex flex-col">
      <button
        key={index}
        className="w-50 h-14 text-lg shadow-xl bg-purple mt-7 text-white font-extrabold flex items-center justify-center rounded-md shadow-md"
        onClick={e => onClick(timeslot)}
      >
        <p>{moment(timeslot.start).tz(timezone).format('dddd h:mm A')}</p>
      </button>
    </div>
  );
}

export default Timeslots;
