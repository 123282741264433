import React, { useEffect, useState } from 'react';

const Toast = ({ message, isVisible, setIsVisible }) => {
  const [fadeState, setFadeState] = useState('opacity-0'); // Initial fade-out state

  useEffect(() => {
    if (isVisible) {
      setFadeState('opacity-100'); // Fade-in animation
      const timer = setTimeout(() => {
        setFadeState('opacity-0'); // Fade-out animation after 5 seconds
        setTimeout(() => {
          setIsVisible(false); // Hide the toast after fade-out
        }, 500);
      }, 5000); // Display toast for 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    } else {
      setFadeState('opacity-0'); // Fade-out immediately if not visible
    }
  }, [isVisible]); // Trigger effect when `isVisible` changes

  const handleOnClick = () => {
    setFadeState('opacity-0');
    setTimeout(() => {
      setIsVisible(false); // Hide the toast after fade-out
    }, 500);
  };

  return (
    <>
      {isVisible && (
        <div
          className={`z-50 max-w-xs bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 transition-opacity duration-500 ease-in-out
          ${fadeState}`}
          role="alert"
          tabIndex="-1"
        >
          <div className="flex">
            {message || 'This is a default toast message.'}
            <div className="ml-auto">
              <button
                type="button"
                className="inline-flex justify-center items-center size-5 rounded-lg text-red-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-red-200"
                onClick={() => handleOnClick()}
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
