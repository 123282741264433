import _ from 'lodash';
import moment from 'moment';
import Timeslots from './Timeslots';

function TimeslotsDaysContainer({ timeslots, date, onClick, className }) {
  // Remove duplicates but use the first one
  const uniqueTimeslots = _.uniqBy(timeslots, 'start');

  return (
    <div className={className}>
      <p className="text-2xl font-extrabold text-center mt-2">{moment(date).format('dddd, MMMM D, YYYY')}</p>
      {
        _.map(uniqueTimeslots, (timeslot, index) => (
          <Timeslots key={index} timeslot={timeslot} index={index} onClick={onClick} />
        ))
      }
    </div>
  );
}

export default TimeslotsDaysContainer;
