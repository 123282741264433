import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import stateCodeToName from '../utils/state_code_to_name.json';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SelectState({ name, label, disabled, placeholder, onChange, value='', type, className}) {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState('');

  const stateCodes = Object.keys(stateCodeToName);
  let stateOptions = stateCodes.map(stateCode => ({label: stateCodeToName[stateCode], value: stateCode}));
  stateOptions = [{label: 'Please select one...', value: ''}].concat(stateOptions);

  if (!className) {
    className = 'mt-1 block w-full border border-light-gray rounded-md shadow-md p-3 font-semibold';
  }

  const disabledClassNames = disabled ? 'bg-disabled' : '';

  className = `
    ${className}
    ${disabledClassNames}
  `;

  const onStateSelect = (e) => {
    e.preventDefault();
    const stateCode = e.target.value;
    setState(stateCode);
    query.set('state', stateCode);
    query.set('type', '500961'); // new patient

    navigate(`/book-appointment?${query.toString()}`);
  };

  return (
    <main className="flex-1 overflow-y-auto p-4 font-sans">
      <div className="h-full p-6 max-w-md mx-auto bg-white rounded-lg text-center mt-0">
        <div className="space-y-4">
          <div className="mt-3">
            <p className="text-3xl font-extrabold">Joyous</p>
            <p className="text-sm font-bold text-gray mt-2">New Patient Mental Wellbeing</p>
            <p className="text-lg font-semibold text-gray text-center mt-4">Select State</p>
          </div>

          <div className="relative inline-flex items-center">
            <select
              // className="mt-1 p-2 pl-12 block w-full font-bold shadow-md rounded-lg"
              className="p-2 block w-full font-bold shadow-md rounded-lg truncate"
              value={state}
              onChange={e => onStateSelect(e)}
            >

              {stateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

        </div>
      </div>
    </main>

  );
}
