
export default function DateOfBirthInput({label, disabled, placeholder, onChange, value, type, options, className}) {
  let dayOptions = [{label: 'Day', value: ''}];
  dayOptions = dayOptions.concat(Array.from({ length: 31 }, (_, i) => ({label: i+1, value: i+1})));

  let monthOptions = [{label: 'Month', value: ''}];
  monthOptions = monthOptions.concat([
    {label: 'January', value: 'January'},
    {label: 'February', value: 'February'},
    {label: 'March', value: 'March'},
    {label: 'April', value: 'April'},
    {label: 'May', value: 'May'},
    {label: 'June', value: 'June'},
    {label: 'July', value: 'July'},
    {label: 'August', value: 'August'},
    {label: 'September', value: 'September'},
    {label: 'October', value: 'October'},
    {label: 'November', value: 'November'},
    {label: 'December', value: 'December'},
  ]);

  let yearOptions = [{label: 'Year', value: ''}];
  // Get array of options from 100 years ago to now
  yearOptions = yearOptions.concat(Array.from({ length: 100 }, (_, i) => ({label: new Date().getFullYear() - i, value: new Date().getFullYear() - i})))

  const disabledClassNames = disabled ? 'bg-disabled' : '';
  className = `
    block w-full border border-light-gray rounded-md shadow-lg h-12 p-3 sm-max:px-1 font-bold
    ${disabledClassNames}
    ${className}
  `;

  return (
    <div>
      <label className="block text-md font-bold">{label}</label>
      <div className="mt-1 grid grid-cols-3 sm-max:grid-cols-[5fr_2fr_3fr] gap-2">

        <select
          name="DOBm"
          id="DOBm"
          className={`${className}`}
          value={value.DOBm}
          onChange={e => onChange(e)}
          placeholder="Month"
          disabled={disabled}
        >
          {monthOptions.map(option=> (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>

        <select
          name="DOBd"
          id="DOBd"
          className={`${className}`}
          value={value.DOBd}
          onChange={e => onChange(e)}
          placeholder="Day"
          disabled={disabled}
        >
          {dayOptions.map(option=> (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>

        <select
          name="DOBy"
          id="DOBy"
          className={className}
          value={value.DOBy}
          onChange={e => onChange(e)}
          placeholder="Year"
          disabled={disabled}
        >
          {yearOptions.map(yearData => (
            <option key={yearData.value} value={yearData.value}>{yearData.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
