

function Loader ({ className}) {
  return (
    <div className={className}>
    <div className="flex justify-center items-center h-full">
    {/* Loader Component */}
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
    {/* Loader styles */}
    <style jsx="true">{`
      .loader {
        border-top-color: #a988e0;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
  </div>
  );
};

export default Loader;
