

export default function TextInput({ name, label, disabled, placeholder, onChange, value='', type, className, onBlur}) {

  if (!className) {
    className = 'mt-1 block w-full border border-light-gray rounded-md shadow-md p-3 font-semibold';
  }

  const disabledClassNames = disabled ? 'bg-disabled' : '';

  className = `
    ${className}
    ${disabledClassNames}
  `;

  if (!onBlur) {
    onBlur = () => {};
  }

  return (
    <div>
      <label className="block text-md font-bold">{label}</label>

      {type == 'text' && (
        <input
          name={name}
          type={type}
          className={className}
          value={value}
          placeholder={placeholder}
          onChange={e => onChange(e)}
          disabled={disabled}
          onBlur={e => onBlur(e)}
        />
      )}
    </div>
  );
}
