import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const CompleteIntake = () => {
  const { currentUser, patientDetails, patientData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const loadedAllData = currentUser && patientDetails && patientData;
  const neverScheduled = patientDetails?.never_scheduled;
  const intakeComplete = patientData?.intake_complete || patientData?.intake_complete === 'undefined';

  const needsToCompleteIntake = loadedAllData && !neverScheduled && !intakeComplete;

  useEffect(() => {
    if (!needsToCompleteIntake) {
      navigate(`/book-appointment${location.search}`);
    }
  }, [needsToCompleteIntake, currentUser, patientDetails,  patientData]);


  const onClick = e => {
    e.preventDefault();
    const magicID = patientData.magic_id;
    let url = 'https://joyous.team/intake';

    if (magicID) {
      window.location = `${url}?id=${magicID}`;
    } else {
      window.location = url;
    }
  };

  return (
    <div>
      {true &&
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-center text-lg md:text-xl font-medium mb-6">
            Looks like we need an updated medical intake for you
            <br />
            before you book this appointment.
          </h2>
          <button
            className="bg-purple hover:bg-purple-600 text-white font-semibold py-3 px-6 rounded-md focus:outline-none mb-4"
            onClick={onClick} >
            Complete Intake
          </button>
          <p className="text-center text-gray-600">
            Upon completion please return here to finish booking
          </p>
        </div>
      }
    </div>
  );
};

export default CompleteIntake;
