

import React, { useState } from 'react';

const PhoneInput = ({ name, label, disabled, className, placeholder, onChange, onFocus, value }) => {
  const [phone, setPhone] = useState(value || '');

  const handleChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    if (value.length > 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
    } else if (value.length > 3) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else if (value.length > 0) {
      value = `(${value}`;
    }

    setPhone(value);
    if (onChange) {
      // format phone to +1XXXXXXXXXX
      e.target.value = `+1${e.target.value.replace(/\D/g, '')}`;
      onChange(e)
    }
  };

  // className = 'mt-1 block w-full border border-light-gray rounded-md shadow-md p-2' + (className ? ` ${className}` : '');
  // className = 'inline-flex items-center px-3 border border-light-gray rounded-r-md p-2' + (className ? ` ${className}` : '');

  const disabledClassNames = disabled ? 'bg-disabled' : '';
  className = `
    inline-flex items-center px-3 border border-light-gray rounded-r-md p-3
    ${className}
    ${disabledClassNames}
  `;


  return (
    <div>
      <label className="block text-md font-semibold">{label}</label>
      <div className="mt-1 flex rounded-md shadow-md font-semibold">
        <span className={`inline-flex items-center px-3 rounded-l-md border border-r-0 border-light-gray ${disabledClassNames}`}>
          🇺🇸 +1
        </span>
        <input
          name={name}
          type="text"
          value={value || phone}
          onChange={e => handleChange(e)}
          onFocus={e => onFocus(e)}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
