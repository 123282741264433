import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { ReactComponent as GlobeIcon } from '../assets/globe.svg';

function TimezonePicker({ onChange }) {
  const usTimezones = [
    'America/New_York',    // Eastern Time
    'America/Chicago',     // Central Time
    'America/Denver',      // Mountain Time
    'America/Phoenix',     // Mountain Time (no DST)
    'America/Los_Angeles', // Pacific Time
    'America/Anchorage',   // Alaska Time
    'America/Adak',        // Hawaii-Aleutian Time
    'Pacific/Honolulu'     // Hawaii Time (no DST)
  ];

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const initialTimezone = usTimezones.includes(localTimezone) ? localTimezone : usTimezones[0];

  const [currentTimes, setCurrentTimes] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState(initialTimezone);

  useEffect(() => {
  // Function to update the time for each timezone
    const updateTimes = () => {
      const newTimes = {};
      usTimezones.forEach((timezone) => {
        newTimes[timezone] = moment.tz(timezone).format('hh:mm A');
      });
      setCurrentTimes(newTimes);
    };

    // Update times immediately and then every minute
    updateTimes();
    const intervalId = setInterval(updateTimes, 60000); // 60000ms = 1 minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    onChange(selectedTimezone);
  }, [selectedTimezone, onChange]);


  return (
    <div className="relative inline-flex items-center mb-8">
      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
        {/* <GlobeIcon className="absolute mt-1 ml-2 h-7 w-7" /> */}
        <GlobeIcon className="h-7 w-7 text-gray-500" />
      </div>
      <select
        // className="mt-1 p-2 pl-12 block w-full font-bold shadow-md rounded-lg"
        className="p-2 pl-12 block w-full font-bold shadow-md rounded-lg appearance-none truncate"
        value={selectedTimezone}
        onChange={(e) => setSelectedTimezone(e.target.value)}
      >

        {usTimezones.map((timezone) => (
          <option key={timezone} value={timezone}>
            {`${timezone} (${moment.tz(timezone).format('z')}) - ${currentTimes[timezone]}`}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TimezonePicker;
