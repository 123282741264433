import axios from 'axios';
import stateCodeToName from './state_code_to_name.json';

const meetingTypeMap = {
  'new': 'New Patient Mental Wellbeing',
  'followup': 'Follow Up Mental Wellbeing',
  '500961': 'New Patient Mental Wellbeing',
  '487240': 'Follow Up Mental Wellbeing',
}

function getStateFullName(state) {
  return stateCodeToName[state];
}

function getMeetingTypeFullName(type) {
  return meetingTypeMap[type];
}

function formatPhoneNumber(phoneNumber) {
  // Remove the +1 prefix
  const strippedNumber = phoneNumber.replace("+1", "");

  // Extract area code, central office code, and station code
  const areaCode = strippedNumber.slice(0, 3);
  const centralOfficeCode = strippedNumber.slice(3, 6);
  const stationCode = strippedNumber.slice(6);

  // Format the phone number with parentheses and a dash
  const formattedNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;

  return formattedNumber;
}

function formatPhoneNumberForDb(phoneNumber) {
  // Remove all non-numeric characters except '+'
  const cleaned = phoneNumber.replace(/[^\d+]/g, '');

  // Check if the phone number starts with '+1'
  if (cleaned.startsWith('+1')) {
    // If it already starts with +1, strip everything else and ensure it has 10 digits after +1
    return '+1' + cleaned.slice(2).replace(/\D/g, '').slice(0, 10);
  } else {
    // If it doesn't start with +1, ensure only the last 10 digits are used
    const digits = cleaned.replace(/\D/g, '').slice(-10);
    return '+1' + digits;
  }
}



async function getDetailsByUid(uid) {
  try {
    const result = await axios.get(`https://drchrone-api.herokuapp.com/get_details/?uid=${uid}`);
    console.log(result)

    return result.data;
  } catch (e) {
    console.error('Error getting details');
  }
}

function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/*
  console.log(isValidPhoneNumber("(650) 450-0089")); // true
  console.log(isValidPhoneNumber("650-450-0089"));    // false
  console.log(isValidPhoneNumber("(650)450-0089"));   // false
*/
function isValidPhoneNumber(phoneNumber) {
  return /^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber);
}

function generateIntakeUrl(formData, magicId) {
  const baseURL = 'https://joyous.team/intake?';
  const firstName = `name[first]=${encodeURIComponent(formData.firstName)}`;
  const lastName = `name[last]=${encodeURIComponent(formData.lastName)}`;
  const DOBm = `dateOf[month]=${encodeURIComponent(formData.DOBm)}`;
  const DOBd = `dateOf[day]=${encodeURIComponent(formData.DOBd)}`;
  const DOBy = `dateOf[year]=${encodeURIComponent(formData.DOBy)}`;
  const phoneNumber = `phoneNumber=${encodeURIComponent(formData.phoneNumber.replace(/^\+1/, '').replace(/\D/g, ''))}`;
  const state = `state=${encodeURIComponent(formData.state)}`;
  const email = `email=${encodeURIComponent(formData.emailForm)}`;

  let url = `${baseURL}${firstName}&${lastName}&${DOBm}&${DOBd}&${DOBy}&${phoneNumber}&${state}&${email}`;

  if (magicId) {
    url += `&magic_id=${encodeURIComponent(magicId)}`;
  }

  return url;
};

async function checkIfEmailExists(email, uid, phone) {
  try {
    const result = await axios.post('https://drchrone-api.herokuapp.com/check_email_exists', {
      email,
      uid,
      phone,
    });

    return result.data;
  } catch (e) {
    console.error('Error checking email:', e);
  }
}

function convertNumberToMonth(number) {
  console.log(number)
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];

  return months[number - 1];
}

function setSessionStorageWithExpiry(key, value, ttl) {
  const now = new Date();

  // 'ttl' is time to live in milliseconds (e.g., 5000 for 5 seconds)
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  // Store the value and expiration time as a JSON string
  sessionStorage.setItem(key, JSON.stringify(item));
}

function getSessionStorageWithExpiry(key) {
  const itemStr = sessionStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the current time with the expiration time
  if (now.getTime() > item.expiry) {
    // If the item has expired, remove it from storage and return null
    sessionStorage.removeItem(key);
    return null;
  }

  // If the item hasn't expired, return its value
  return item.value;
};

async function getDrFromDxOrDr({dx, dr}) {
  try {
    let url = `https://us-central1-joyous-web.cloudfunctions.net/v2/dxToDr`;
    if (dx) {
      url += `?dx=${dx}`;
    } else if (dr) {
      url += `?dr=${dr}`;
    }
    const result = await axios.get(url);
    return result.data;
  } catch (e) {
    console.error('Error getting doctor from dx:', e);
  }
}

export {
  getStateFullName,
  getMeetingTypeFullName,
  formatPhoneNumber,
  formatPhoneNumberForDb,
  getDetailsByUid,
  isValidEmail,
  isValidPhoneNumber,
  generateIntakeUrl,
  checkIfEmailExists,
  convertNumberToMonth,
  setSessionStorageWithExpiry,
  getSessionStorageWithExpiry,
  getDrFromDxOrDr,
};
